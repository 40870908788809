<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
          <b-img
            :src="logoImg"
            alt="logo"
          />
      </b-link> -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
        style="background-color:#ffffff;"
      >
        <div class="w-100 d-lg align-items-center justify-content-center px-5">
          <!-- <b-card-title
            title-tag="h5"
            class="font-weight-bold mb-1"
          >
           Submit an anonymous report
          </b-card-title> -->
          <!-- <b-button
            type="submit"
            variant="primary"
            block
            @click="$router.push('/report')"
          >
            Go to page
          </b-button> -->
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
           👋 Benvenuto su {{appName}}
          </b-card-title>
        <b-card-title
          title-tag="h5"
          class="font-weight-bold mb-1"
        >
          autentica il tuo account
        </b-card-title>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'forgotPassword'}">
                    <small>Password dimenticata?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Ricorda account
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Entra
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'page-auth-register-v2'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login.jpg'),
      logoImg: require('@/assets/images/logo/logo.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      userRoles: {
        'usr': 'Utente',
        'adm': 'Admin',
        'rsp': 'Responsabile azienda',
      },
      appName: $themeConfig.app.appName,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$http.post('/v1/auth/login-referer', {
                email: this.userEmail,
                password: this.password,
              },
            ).then(res => {
                // localStorage.clear()
                useJwt.setToken(res.data.token)
                // useJwt.setRefreshToken(res.data.refreshToken)
                localStorage.setItem('azUuid', res.data.typeUuid)
                localStorage.setItem('userUuid', res.data.uuid)
                localStorage.setItem('name', res.data.userDetail['name'] ? res.data.userDetail.name : '')
                localStorage.setItem('surname', res.data.userDetail['surname'] ? res.data.userDetail.surname : '')
                localStorage.setItem('fullname', res.data.azienda.ragioneSociale)
                // localStorage.setItem('fullname', [res.data.userDetail.name,res.data.userDetail.surname].join(''))
                localStorage.setItem('userData', JSON.stringify(res.data))
                localStorage.setItem('role', this.userRoles[res.data.userDetail.userType])
                // to retrive js object use JSON.parse(localStorage['userData'])
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Login Submitted',
                    icon: 'KeyIcon',
                    variant: 'success',
                  },
                })
                if (res.data.firstLogin) this.$router.push(`/resetPassword/${res.data.token}/true`)
                else{
                  if(this.$route.query.to!=null&&document.cookie.includes("toIsSet=true"))
                  {
                    this.$router.push(this.$route.query.to)
                  }
                  else
                  {
                    this.$router.push('home')
                  }
                  
                }
              }).catch( e => {
                  console.log(e)
                  if(e.response.status==400)
                  {
                      this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Errore',
                      text: 'Username e/o password errati',
                      icon: 'XIcon',
                      variant: 'warning',
                    },
                })
                  }
                  else
                  {
                    this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Errore',
                      text: 'Qualcosa è andato storto, riprovare più tardi',
                      icon: 'XIcon',
                      variant: 'warning',
                    },
                })
                  }
                  
              })
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
